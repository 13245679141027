.load-guard-tab-container {
  margin-left: 130px !important;

  i.la.la-info-circle {
    margin-bottom: 3px;
  }
}

.devices-tab-panel {
  position: absolute !important;
  right: 0;
  top: -41px;
}

.fakeField {
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.load-guard-tooltip {
  div.MuiTooltip-tooltip {
    background-color: #0A1627;
  }
}

.form-group.m-form__group.input-field.switchingTimes-field {
  height: auto !important;
}

@media (max-width: 1380px) {
  .load-guard-tab-container {
    margin-left: 0 !important;
  }

  .react-tabs {
    .loadguards {
      margin-bottom: 60px;
    }
  }
}

.sub-meter {
  .react-tabs__tab-list {
    border: none !important;
    margin-top: 20px !important;
  }

  .nav-link.m-tabs__link:hover {
    color: #fff !important;
    border-bottom: #fff !important;
    cursor: default;
  }
}

.sub-meter-data-list {
  .rt-thead.-header {
    display: none !important;
  }
}